config = {
    templateBaseUrl: '/views/',
    useHtml5Mode: false,

    api_url: "https://k-api.lefresnoy.net/",
    rest_uri_v2: "https://k-api.lefresnoy.net/v2/",
    rest_uri: "https://k-api.lefresnoy.net/v1/",

    analytics_uri: "https://www.lefresnoy.net/analytics/",
    analytics_site_id: 5
};
